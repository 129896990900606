body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ************ Style temporaire ************ */
body {
  font-size: 16px;
}

input {
  padding: 10px 10px;
  margin: 10px;

  border: 1px solid #838383;
  border-radius: 3px;

  font-size: 16px;
}

button {
  background-color: #ec6331;
  border: #ec6331 2px solid;
  border-radius: 5px;
  
  padding: 10px 20px;
  margin: 5px;

  color: #ffffff;
  font-size: 16px;

  cursor: pointer;
  transition: all ease-in-out .2s;
}

button:hover {
  background-color: transparent;
  color: #ec6331;
}

.block {
  display: block;
  margin-top: 10px;
}

.App header > div {
  width: 100%;
  background: #f7f7f7;
  border-radius: 10px;

  padding: 10px 10px;
  margin: 0.2em auto;
  
}

.App header > div > div,
.App header > div > form {
  border-bottom: 1px solid #d1d1d1;
  padding: 0.2em 0 1em;
}

.App header > div > *:last-child {
  border: none;
}

.div_border_bottom{
  border-bottom: 1px solid #d1d1d1;
  padding:0.5em
}

h2{
  margin:2px
}